<template>
  <div class="wrapper campaign-single">
    <BackLink :data="backlink" />
    <img
      :src="campanhasImage.bannerSrc"
      :alt="campanhasImage.bannerAlt"
      :title="campanhasImage.bannerText"
      class="banner red-left"
    />
    <Section>
      <IndexInfoBlock
        :title="campanhasDetail.title"
        :content="campanhasDetail.content"
        positionClass="right-side "
      />
    </Section>
    <Section>
      <Separator />
    </Section>
    <Section>
      <IndexInfoBlock
        subTitle="Gostaria de Saber Mais Sobre Esta Campanha?"
        positionClass="right-side "
      >
      </IndexInfoBlock>
      <Form
        v-if="
          campanhasDetail.forms.lenght > 0 ||
          Object.keys(campanhasDetail.forms).length > 0
        "
        :data="campanhasDetail.forms"
        @changeOption="changeOption"
      />
    </Section>
    <Section v-if="legal">
      <HeadingBlock
        :data="{ blockText: legal }"
        textClass="legal "
        blockClass="full-width "
      />
    </Section>
    <Section v-if="seo">
      <SEO
        :seo="seo.single"
        :banner="seo.single.seoBannerImage"
        :link="seo.single.seoBannerLink"
      />
    </Section>
  </div>
</template>

<script>
import BackLink from "@/components/Index/BackLink.vue";
import Form from "@/components/Forms/Form.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import IndexInfoBlock from "@/components/Index/IndexInfoBlock.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";
import Separator from "@/components/Separator/Separator.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "CampaignsDetail",
  metaInfo() {
    return manageSEO(
      this.seo,
      this.seoHomepage,
      true,
      this.dealerName,
      null,
      null,
      this.campanhasDetail.title
    );
  },
  components: {
    BackLink,
    Form,
    HeadingBlock,
    IndexInfoBlock,
    Section,
    SEO,
    Separator,
  },
  data() {
    return {
      backlink: {
        routting: "Campanhas",
        label: "campanhas",
      },
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  methods: {
    changeOption(e) {
      this.dealerDetail.forEach((element) => {
        if (element.objectId == e) {
          element.contacts[0].contactPersons[0].contactList.forEach((el) => {
            if (el.designation == "E-mail") {
              let customLead = document.getElementById(
                this.$route.params.slug + "-form-custom_lead"
              );
              customLead.value = el.value;
            }
          });
        }
      });
    },
  },
  computed: {
    campanhasData() {
      return this.$store.getters.getAllCampaigns;
    },
    campanhasDetail() {
      return this.campanhasData.find((campaign) => {
        return campaign.slug == this.$route.params.slug;
      });
    },
    dealerDetail() {
      return this.$store.state.allInstallations;
    },
    campanhasImage() {
      return {
        bannerSrc: this.campanhasDetail.bannerImage.imageUrl,
        bannerAlt: this.campanhasDetail.bannerImage.imageAlt,
        bannerText: this.campanhasDetail.bannerImage.imageTitle,
        imageSrc: this.campanhasDetail.imageHighlight.imageUrl,
        imageAlt: this.campanhasDetail.imageHighlight.imageAlt,
        imageText: this.campanhasDetail.imageHighlight.imageTitle,
      };
    },
    seo() {
      return this.$store.state.campanhasSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo;
    },
    tcapSource() {
      return this.$store.state.campanhasSourceTCAP;
    },
    dealerSource() {
      return this.$store.state.campanhasSourceDealer;
    },
    legal() {
      return this.campanhasDetail.source == "tcap"
        ? this.tcapSource
        : this.dealerSource;
    },
  },
};
</script>
