<template>
  <div class="fluid-element">
    <div class="separator"></div>
  </div>
</template>

<script>
export default {
  name: "Separator",
};
</script>

<style lang="scss" scoped>
@import "/Separator";
</style>
